import React, { Component } from "react";

import Helmet from "react-helmet";
import Layout from "src/layouts/LayoutWhiteHeader";
import SEO from "src/components/generic/SEO/SEO";
import ToFromWidget from "src/components/generic/ToFromWidget/ToFromWidget";

import t from "tcomb-form";
import templates from "tcomb-form-templates-bulma";

import { downloadAllDataNonBlocking } from "src/services/tools/individualTools/getSearchConsole/downloadSearchConsoleData";
import { buildURI } from "src/services/csv/csv";
import { update, safeLowerString } from "src/utils/utils";
import { formatDate } from "src/utils/dateUtils";
import { differenceInDays } from "date-fns";

// Meta image
import socialImage from "static/images/resources/download-search-console/social.png";

// TCOMB SETUP
const { Form } = t.form;
t.form.Form.templates = templates;

// const Dimensions = t.enums.of("device country page query");

const downloadSearchConsoleTComb = t.struct({
	propertyUri: t.String,
	dimensions: t.list(t.String)
});

class DownloadSearchConsole extends Component {
	// Load in the auth in a horrible way
	// https://github.com/google/google-api-javascript-client/issues/319
	constructor(props) {
		super(props);
		this.authorize = this.authorize.bind(this);
		this.signOut = this.signOut.bind(this);
		this.initClient = this.initClient.bind(this);

		this.state = {
			googleApiEnabled: false,
			areWeAuthorized: false,
			formStatus: "loading",
			downloadPercentage: 0,
			dataError: null,
			downloadDayMax: null,
			formValues: {
				startDate: null,
				endDate: null
			},
			tCombOptions: {
				auto: "placeholders",
				fields: {
					dimensions: {
						factory: t.form.Select,
						attrs: {
							placeholder:
								"Select the dimensions you want to download data for"
						},
						help:
							"Select the dimensions you want to download data for",
						options: [
							{
								value: "device",
								text: "device"
							},
							{
								value: "country",
								text: "country"
							},
							{
								value: "page",
								text: "page"
							},
							{
								value: "query",
								text: "query"
							}
						],
						nullOption: {
							value: "",
							text: `Select your dimensions.`
						}
					},
					propertyUri: {
						factory: t.form.Select,
						attrs: {
							placeholder:
								"Select the GSC property you want to download data for."
						},
						options: [],
						nullOption: {
							value: "",
							text: `Select your property.`
						},
						help:
							"If you can't see any properties, then re-authorise."
					}
				}
			}
		};
	}

	componentDidMount() {
		const gapiScript = document.createElement("script");
		gapiScript.src = "https://apis.google.com/js/api.js?onload=onGapiLoad";
		window.onGapiLoad = () => {
			gapi.load("client", { callback: this.initClient });
		};
		document.body.appendChild(gapiScript);
	}

	/**
	 * This turns on the Google client API
	 */
	// eslint-disable-next-line react/sort-comp
	initClient() {
		gapi.client
			.init({
				// apiKey: apiKey,
				discoveryDocs: [
					"https://searchconsole.googleapis.com/$discovery/rest?version=v1"
				],
				clientId:
					"450136438498-uph12mlg7k1gv1ech4993f2b6fe311f3.apps.googleusercontent.com",
				scope: "https://www.googleapis.com/auth/webmasters.readonly"
			})
			.then(() => {
				this.setState({
					googleApiEnabled: true,
					formStatus: "loading"
				});

				// console.log(gapi.auth2.getAuthInstance().isSignedIn.fe);

				if (gapi.auth2.getAuthInstance().isSignedIn.fe) {
					this.setPropertyUriOptions();
				} else {
					this.setState({
						googleApiEnabled: true,
						formStatus: "ready"
					});
				}
			});
	}

	setPropertyUriOptions() {
		// Go and get the list of properties we can access
		gapi.client.webmasters.sites.list().then(response => {
			const propertyOptions = response.result.siteEntry.map(site => {
				return {
					value: site.siteUrl,
					text: site.siteUrl
				};
			});

			const updatedState = update(
				["fields", "propertyUri", "options"],
				propertyOptions,
				this.state.tCombOptions
			);

			this.setState({
				tCombOptions: updatedState,
				formStatus: "ready",
				areWeAuthorized: true
			});
		});
	}

	removePropertyOptions() {
		const updatedState = update(
			["fields", "propertyUri", "options"],
			[],
			this.state.tCombOptions
		);
		this.setState({
			tCombOptions: updatedState
		});
	}

	// eslint-disable-next-line class-methods-use-this
	async authorize() {
		await gapi.auth2.getAuthInstance().signIn();

		this.setPropertyUriOptions();
	}

	// eslint-disable-next-line class-methods-use-this
	signOut() {
		gapi.auth2.getAuthInstance().signOut();
		gapi.auth2.getAuthInstance().disconnect();

		this.removePropertyOptions();

		this.setState({
			areWeAuthorized: false
		});
	}

	// eslint-disable-next-line consistent-return
	onFormChange = (value, path) => {
		// Component may not exist if we're deleting elements of the ruleset
		// in which case skip validation.
		const component = this.downloadSearchConsoleRef.getComponent(path);

		if (component) {
			component.validate();
		} else {
			return true;
		}

		this.setState({
			formValues: value
		});
	};

	onFormSubmit = async e => {
		e.preventDefault();

		// This is tcomb fun that gets the values from
		// the form
		const value = this.downloadSearchConsoleRef.getValue();

		if (value) {
			this.setState({
				formStatus: "downloading",
				downloadPercentage: 0,
				downloadDayMax: Math.abs(
					differenceInDays(
						this.state.formValues.startDate,
						this.state.formValues.endDate
					)
				)
			});

			const results = await downloadAllDataNonBlocking(
				this.state.formValues.propertyUri,
				this.state.formValues.startDate,
				this.state.formValues.endDate,
				this.state.formValues.dimensions,
				this.updateDownloadBar
			);

			if (typeof results === "string") {
				this.setState({
					dataError: results,
					formStatus: "ready"
				});
			} else {
				const uri = buildURI(results, true, null, ",", '"');

				this.setState({
					downloadUrl: uri,
					formStatus: "ready"
				});
			}
		}
	};

	updateDownloadBar = number => {
		this.setState({
			downloadPercentage: number
		});
	};

	dateFromHandle = value => {
		const newFormValues = update(
			["startDate"],
			value,
			this.state.formValues
		);

		this.setState({
			formValues: newFormValues
		});
	};

	dateToHandle = value => {
		const newFormValues = update(["endDate"], value, this.state.formValues);

		this.setState({
			formValues: newFormValues
		});
	};

	render() {
		let downloadButton;
		if (this.state.downloadUrl) {
			const safeSite = `${safeLowerString(
				this.state.formValues.propertyUri
			)}_${safeLowerString(
				formatDate(this.state.formValues.startDate)
			)}_${safeLowerString(formatDate(this.state.formValues.endDate))}`;

			downloadButton = (
				<a
					download={`sc_${safeSite}.csv`}
					href={this.state.downloadUrl}
					className="m-b-m button is-success"
					type="button"
				>
					Download CSV
				</a>
			);
		}

		// Generate date fields
		// There is no default tcomb widget for date widgets and I've never bothered
		// to integrate them so I just mush them in, in the ugliest way possible.

		let form;
		if (
			this.state.formStatus === "loading" ||
			!this.state.googleApiEnabled
		) {
			form = <div className="loaderLarge clear-both" />;
		} else if (this.state.formStatus === "downloading") {
			form = (
				<progress
					className="progress"
					value={this.state.downloadPercentage}
					max={this.state.downloadDayMax}
				>
					{this.state.downloadPercentage} days downloaded.
				</progress>
			);
		} else {
			form = (
				<React.Fragment>
					<form
						id="downloadSearchConsole"
						className={`toolBox downloadSearchConsole`}
						onSubmit={this.onFormSubmit}
						data-testid="dscForm"
					>
						<h2 className="is-size-6 p-b-md">
							Download search console data
						</h2>
						{downloadButton}
						<Form
							ref={ref => {
								this.downloadSearchConsoleRef = ref;
							}}
							type={downloadSearchConsoleTComb}
							options={this.state.tCombOptions}
							value={this.state.formValues}
							onChange={this.onFormChange}
						/>
						<ToFromWidget
							from={this.state.formValues.startDate}
							to={this.state.formValues.endDate}
							fromChangeFunction={this.dateFromHandle}
							toChangeFunction={this.dateToHandle}
						/>
						<div className="field is-grouped bottomOfForm">
							<button
								type="submit"
								className="button is-primary control m-t-md"
							>
								Submit
							</button>
						</div>
					</form>
				</React.Fragment>
			);
		}

		let googleFormMessage = (
			<div className="message">
				<div className="message-body">
					The Google Client API needs to download for this to work. If
					you can still see this message it hasn&apos;t downloaded
					yet.
				</div>
			</div>
		);
		if (this.state.googleApiEnabled) {
			googleFormMessage = "";
		}

		let dataError;
		if (this.state.dataError) {
			dataError = (
				<div className="message">
					<div className="message-body">{this.state.dataError}</div>
				</div>
			);
		}

		let areWeAuthorized;
		if (!this.state.areWeAuthorized) {
			areWeAuthorized = (
				<div className="message">
					<div className="message-body">
						Please sign in with a Google profile using Authorize.
					</div>
				</div>
			);
		}

		return (
			<Layout>
				<SEO
					pageTitle="[Free Tool] Download all your Search Console data"
					pageImage={socialImage}
				/>
				<section className="container m-t-xl m-b-xl">
					<h1>Search Console Data Downloader</h1>

					<div className="columns">
						<div className="column">
							<p>
								This tool will help you download all of your
								search console data.
							</p>
							<h3>How to use it?</h3>
							<p>
								First you&apos;ll need to authorize so it can
								access you search console profiles.
							</p>
							<p>
								Don&apos;t worry this all runs locally in the
								browser and we won&apos;t do anything with your
								data other than download it in the browser and
								give it straight back to you.
							</p>
							<div>
								<button
									type="button"
									className="button is-primary control m-b-m"
									onClick={this.authorize}
								>
									Authorize
								</button>
								<button
									type="button"
									className="button control m-b-m m-l-m"
									onClick={this.signOut}
								>
									Sign Out
								</button>
							</div>
							<div>
								When you&apos;ve done that, it&apos;s pretty
								simple!
							</div>
							<ol>
								<li>
									Select the search console property you want
									to download.
								</li>
								<li>
									Select the dimensions you want to download
									data for. If you want more than one,
									Ctrl+Click (or presumably Cmd+Click on a
									Mac).
								</li>
								<li>Select the start date you want.</li>
								<li>Select the end data date you want.</li>
								<li>Hit submit.</li>
								<li>When it completes, hit Download CSV.</li>
							</ol>
							<h3>Things to watch out for</h3>
							<p>
								It all runs in your browser. We&apos;ve tested
								up to a couple months of data on some reasonably
								large accounts.
							</p>
							<p>
								But there probably is a point where it falls
								over because the browser can&apos;t handle it.
							</p>
							<p>
								If you find your self doing this regularly / for
								multiple profiles / wanting to store it
								somewhere - Piped Out might be worth a look!
							</p>
							<h3>Privacy</h3>
							<p>
								As we said earlier, your data won&apos;t go past
								your browser, but if you&apos;re feeling
								particularly privacy conscious you can always
								revoke our access in your Google Account admin.
								Instructions{" "}
								<a href="https://www.sanebox.com/help/256-how-to-revoke-oauth-permissions-from-an-app-i-gave-access-to-my-gmail-with-google-oauth">
									here
								</a>
								.
							</p>
							<h3>Build notes</h3>
							<p>
								Blog post{" "}
								<a href="/resources/notes-on-building-search-console-data-downloader/">
									here
								</a>{" "}
								has more details on how it&apos;s built & any
								issues you might encounter.
							</p>
						</div>
						<div className="column">
							{googleFormMessage}
							{areWeAuthorized}
							{dataError}
							{form}
						</div>
					</div>
				</section>
			</Layout>
		);
	}
}

export default DownloadSearchConsole;
